import React from 'react';
import {Link} from 'gatsby';
import StandardLayout from '../layouts/standard';
import PageCover from '../components/page-cover';
import decorativeLaminates from '../images/dnd/dl.png';
import specialSurfaces from '../images/dnd/special-surfaces.png';
import stylamIcon from '../images/dnd/stylam-icon.png';
import puCoating from '../images/dnd/pu-coating.png';

export default function ArchitectsAndInteriorDesigners(props) {
    return <StandardLayout title="Architects and Interior Designers">
        <div>
            <PageCover title="" description=""></PageCover>
        </div>
        <section className="uk-section">
           <div className="uk-container uk-margin">
               <div>
                   <h1 className="uk-h1 color-primary uk-text-uppercase uk-text-center">
                   INFRASTRUCTURE
                   </h1>
                   <div className="uk-margin">
                   Stylam Industries Limited owns a well-furnished,Asia's largest installation unit and has a production capacity around 1.3 million sheets of laminates per month.
                   </div>
                   <button className="uk-button uk-button-secondary">
                       Learn More
                   </button>

               </div>
           </div>

           <div className="uk-container  uk-margin-large">
                <div className="uk-card uk-card-default uk-padding-small">
                    <div class="uk-inline">
                        <img src={decorativeLaminates} alt=""/>
                        <div class="uk-overlay uk-overlay-primary uk-position-bottom">
                            <div className="uk-flex uk-flex-between">
                                <h2 className="uk-h2 uk-padding-remove">
                                    Decorative laminates
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="uk-container uk-margin-large">
                <div className="uk-card uk-card-default uk-padding-small uk-grid-collapse" data-uk-grid>
                    <div className="uk-width-expand@s uk-width-1-1">
                        <div class="uk-inline">
                            <img src={specialSurfaces} className="uk-width-expand" alt=""/>
                            <div class="uk-overlay uk-overlay-primary uk-position-bottom">
                                <div className="uk-flex uk-flex-between">
                                    <h2 className="uk-h2 uk-padding-remove">
                                        Special Surfaces
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2@s uk-width-1-1 uk-padding-small">
                            <div className="uk-column-1-2@s">
                                <div>
                                <Link to="" className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin">
                                    <img src={stylamIcon} className="uk-margin-small-right" alt=""/>
                                        Electrostatic Laminates
                                </Link>
                                </div>
                                <div>
                                <Link to="" className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin">
                                    <img src={stylamIcon} className="uk-margin-small-right" alt=""/>
                                        Electrostatic Laminates
                                </Link>
                                </div>
                            </div>
                    </div>
                </div>
            </div>

            <div className="uk-container uk-margin-large">
                <div className="uk-card uk-card-default uk-padding-small uk-grid-collapse" data-uk-grid>
                    <div className="uk-width-expand@s uk-width-1-1">
                        <div class="uk-inline">
                            <img src={puCoating} className="uk-width-expand" alt=""/>
                            <div class="uk-overlay uk-overlay-primary uk-position-bottom">
                                <div className="uk-flex uk-flex-between">
                                    <h2 className="uk-h2 uk-padding-remove">
                                    PU+ lacquer coating
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3@s uk-width-1-1 uk-padding-small uk-align-center uk-align-middle">
                            <div>
                                <div>
                                <Link to="" className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin">
                                    <img src={stylamIcon} className="uk-margin-small-right" alt=""/>
                                        Electrostatic Laminates
                                </Link>
                                </div>
                                <div>
                                <Link to="" className="uk-button uk-button-default uk-flex uk-flex-middle uk-margin">
                                    <img src={stylamIcon} className="uk-margin-small-right" alt=""/>
                                        Electrostatic Laminates
                                </Link>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    </StandardLayout>
}